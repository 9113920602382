import React, { useState } from "react";
import { motion } from "framer-motion";
import { Grid, IconButton, Skeleton } from "@chakra-ui/core";
import { usePaginatedQuery } from "react-query";
import { handleGetRequest } from "../../helpers/handle-response";
import TravelCard from "./TravelCard";
import { useInView } from "react-intersection-observer";

const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop);

const Travel = (props) => {
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = React.useState(1);
  const executeScroll = () => scrollToRef(topRef);
  const topRef = props.scrollTo;
  const [ref, inView] = useInView();

  const { isLoading, data } = usePaginatedQuery(
    [props.queryName, page],
    (key, page = 1) =>
      handleGetRequest(props.url + `&per_page=4&page=${page}`).then((res) => {
        setTotalPages(Number(res.headers["x-wp-totalpages"]));
        return res.data;
      }),
    { enabled: inView }
  );

  return (
    <motion.div
      initial="initial"
      animate={inView ? "enter" : null}
      exit="exit"
      variants={{ exit: { transition: { staggerChildren: 0.1 } } }}
    >
      <Grid
        templateColumns={{ base: "1fr", md: "1fr 1fr" }}
        maxWidth="1100px"
        width="100%"
        mx="auto"
        ref={ref}
      >
        {data && !isLoading ? (
          data.map((item) => {
            return (
              <TravelCard
                key={item.id}
                maxW="350px"
                maxH="233.5px"
                noDesc={true}
                btnMt={1}
                btnSize="sm"
                data={item}
              />
            );
          })
        ) : (
          <Grid
            templateColumns={{ base: "1fr", md: "1fr 1fr" }}
            maxWidth="1100px"
            width="100%"
            mx="auto"
          >
            {[1, 2, 3, 4].map((item) => {
              return (
                <Skeleton
                  key={item}
                  width="512px"
                  height="200px"
                  m="15px"
                  borderRadius="25px"
                ></Skeleton>
              );
            })}
          </Grid>
        )}

        <div>
          {page > 1 && page <= totalPages ? (
            <IconButton
              backgroundColor="gray.400"
              w="55px"
              ml="1em"
              icon="arrow-back"
              color="white"
              borderRadius={25}
              h={30}
              _hover={{
                backgroundColor: "gray.500",
                transform: "scale(1.1)",
                transition: 0.3,
              }}
              onClick={() => {
                setPage(page - 1);

                executeScroll();
              }}
            >
              Natrag
            </IconButton>
          ) : null}
          {page < totalPages ? (
            <IconButton
              backgroundColor="gray.400"
              w="55px"
              h={30}
              color="white"
              ml="1em"
              icon="arrow-forward"
              borderRadius={25}
              _hover={{
                backgroundColor: "gray.500",
                transform: "scale(1.1)",
                transition: 0.3,
              }}
              onClick={() => {
                setPage(page + 1);
                executeScroll();
              }}
            >
              Sljedeća
            </IconButton>
          ) : null}
        </div>
      </Grid>
    </motion.div>
  );
};

export default Travel;
