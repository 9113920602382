import React from "react";
import { AnimatePresence } from "framer-motion";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { useWindowSize } from "../../hooks";
import CategoryPage from "../CategoryPage/CategoryPage";
import Header from "../Header/Header";
import SearchResult from "../SearchResult/SearchResult";
import Nav from "../Nav/Nav";
import Main from "../Main/Main";
import Post from "../Post/Post";
import Footer from "../Footer/Footer";
import "./App.css";

/* function Dummy() {
  return (
    <motion.div initial="initial" animate="enter" exit="exit">
      <h2>Dummy component</h2>
      <h2>Dummy component</h2>
    </motion.div>
  );
} */

function App() {
  const size = useWindowSize();
  return (
    <Router>
      <Route
        render={({ location }) => (
          <>
            <div>
              <Header size={size} />
              <Nav />
            </div>
            <div style={{ position: "relative", minHeight: "100vh" }}>
              <AnimatePresence
                exitBeforeEnter
                initial={true}
                onExitComplete={() => {
                  window.scrollTo(0, 0);
                }}
              >
                <Switch location={location} key={location.pathname}>
                  <Route exact path="/" component={Main} />
                  <Route exact path="/na-putu" component={CategoryPage} />
                  <Route exact path="/fun" component={CategoryPage} />
                  <Route exact path="/za-stolom" component={CategoryPage} />
                  <Route exact path="/zdravlje" component={CategoryPage} />
                  <Route exact path="/relax" component={CategoryPage} />
                  <Route exact path="/search/:query" component={SearchResult} />
                  <Route path="/:id" component={Post} />
                </Switch>
              </AnimatePresence>
            </div>
            <Footer />
          </>
        )}
      />
    </Router>
  );
}

export default App;
