import React, { useRef } from "react";
import { Box, Grid, Flex, Heading } from "@chakra-ui/core";
import { motion } from "framer-motion";
import { useLocation } from "react-router-dom";
import { handleGetRequest } from "../../helpers/handle-response";
import { useQuery } from "react-query";
import MainGallery from "../MainGallery/MainGallery";
import GalleryPage from "../GalleryPage/GalleryPage";
import Events from "../Events/Events";
import styled from "@emotion/styled";

const MFlex = motion.custom(Flex);

const categoryNames = {
  "/na-putu": "Na putu",
  "/fun": "Zabava",
  "/za-stolom": "Za stolom",
  "/zdravlje": "Zdravlje",
  "/relax": "Relax",
};
const transition = {
  duration: 1,
  ease: [0.43, 0.13, 0.23, 0.96],
};

const titleVariant = {
  exit: { y: "10%", opacity: 0, transition },
  enter: {
    y: "0%",
    opacity: 1,
    transition,
  },
};
const CategoryPage = () => {
  const topRef = useRef(null);
  //posts?filter[category_name]=MyCategory
  let location = useLocation();
  const { data } = useQuery(
    `categories-${location.pathname.replace("/", "")}`,
    () =>
      handleGetRequest(
        "https://www.viahr-wp.parpar.hr/wp-json/wp/v2/categories"
      ).then((res) => {
        const id = res.data.find((item) => {
          if (item.name === categoryNames[location.pathname]) return item;
          return null;
        });

        return id;
      })
  );

  return (
    <MFlex
      initial="exit"
      animate="enter"
      exit="exit"
      maxW="1400px"
      flexWrap="wrap"
      mx="auto"
      px="15px"
    >
      {data ? (
        <MainGallery
          galleryName={`gallery-${location.pathname}`}
          url={`https://www.viahr-wp.parpar.hr/wp-json/wp/v2/posts?_embed&categories=${data.id}`}
        />
      ) : (
        <Box h="100vh" />
      )}
      <Box>
        <MTitle variants={titleVariant} mt={20} mb={10} ref={topRef} w="200px">
          Najnovije
        </MTitle>
        <Grid
          templateColumns={{ base: "1fr", md: "3fr 1fr" }}
          spacing={{ base: 1, lg: 10 }}
        >
          {data ? (
            <GalleryPage
              scrollTo={topRef}
              url={`https://www.viahr-wp.parpar.hr/wp-json/wp/v2/posts?_embed&categories=${data.id}`}
              queryName={`paged-${location.pathname}`}
            />
          ) : (
            <Box h="100vh" />
          )}
          <Events />
        </Grid>
      </Box>
    </MFlex>
  );
};
export default CategoryPage;

const Title = styled(Heading)`
  margin-top: 20px;
  margin-bottom: 50px;
  position: relative;
  padding: 0 0.5em;
  color: ${(props) => (props.textClr ? props.textClr : "black")};
  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    border-radius: 25px;
    left: 0;
    width: 100%;
    height: 35%;
    background-color: ${(props) => (props.clr ? props.clr : "lightblue")};
    z-index: -10;
    -webkit-transition: all 0.1s linear;
    -moz-transition: all 0.1s linear;
    transition: all 0.1s linear;
  }
`;
const MTitle = motion.custom(Title);
