import React, { useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { Box, IconButton, Input } from "@chakra-ui/core";
import { AnimatePresence, motion } from "framer-motion";
import { useOnClickOutside } from "../../hooks/index";
import { useForm } from "react-hook-form";

const inputVariants = {
  initial: {
    opacity: 0,
    x: 50,
    scale: 0.3,
  },
  enter: {
    opacity: 1,
    x: 0,
    scale: 1,
  },
  exit: {
    opacity: 0,
    scale: 0.5,
    transition: { duration: 0.3 },
  },
};
const MotionButton = motion.custom(IconButton);
const MInput = motion.custom(Input);

const Search = () => {
  let history = useHistory();
  const { register, handleSubmit, errors } = useForm();
  const root = useRef(null);
  const [searchOpen, setOpen] = useState({ icon: true, input: false });

  useOnClickOutside(root, () => {
    setOpen({ icon: false, input: false });
    setTimeout(() => {
      setOpen({ icon: true, input: false });
    }, 300);
  });
  const submit = (data) => {
    setOpen({ icon: true, input: false });
    /*  if (history.location.pathname.includes("/search")) {
      window.location.reload(`/search/${data.search}`);
    } else { */
    history.push(`/search/${data.search}`);
    /*  } */
  };
  return (
    <Box mt={{ base: 2, md: 0 }}>
      <AnimatePresence exitBeforeEnter initial={false}>
        {searchOpen.icon && (
          <MotionButton
            onClick={() => {
              setOpen({ input: false, icon: false });
              setTimeout(() => {
                setOpen({ icon: false, input: true });
              }, 300);
            }}
            whileHover={{ y: -3, transition: { duration: 0.05 } }}
            initial={{
              x: -10,
              opacity: 0,
              transition: { duration: 0.05 },
            }}
            animate={{ opacity: 1, x: 0, scale: 1 }}
            exit={{
              opacity: 0,
              scale: 0.5,
              x: 20,
              transition: { duration: 0.3 },
            }}
            bg="transparent"
            color="black"
            icon="search"
          ></MotionButton>
        )}
        {searchOpen.input && (
          <motion.form
            autoComplete="off"
            ref={root}
            onSubmit={handleSubmit(submit)}
          >
            <MInput
              name="search"
              ref={register({ required: "Obavezno polje" })}
              initial="exit"
              animate="enter"
              exit="exit"
              variants={inputVariants}
              autoFocus
              placeholder="Pretraga..."
              variant="flushed"
              p="10px"
              color="black"
              bg="transparent"
              icon="search"
            />
            {errors.search && (
              <Box as="span" color="red.500" textAlign="center">
                Obavezno polje
              </Box>
            )}
          </motion.form>
        )}
      </AnimatePresence>
    </Box>
  );
};
export default Search;
