import React from "react";
import { Box, Badge, Image } from "@chakra-ui/core";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import Bckg from "../../assets/Rectangle.png";
//import { formatMonth } from "../../helpers/functions";
import { LazyLoadComponent } from "react-lazy-load-image-component";

import style from "./GastroCard.module.scss";

const MBox = motion.custom(Box);

const transition = { duration: 0.5, ease: [0.43, 0.13, 0.23, 0.96] };

const thumbnailVariants = {
  initial: { scale: 0.9, opacity: 0 },
  enter: { scale: 1, opacity: 1, transition },
  exit: {
    scale: 0.5,
    opacity: 0,
    transition: { duration: 1, ...transition },
  },
  hover: { scale: 1.05 },
};

const GastroCard = ({ data, maxH }) => {
  return (
    <MBox
      m={{ base: "5px", lg: "10px" }}
      variants={thumbnailVariants}
      className={style.box}
      cursor="pointer"
      maxW="sm"
      borderWidth="0px"
      rounded="lg"
      whileHover="hover"
      overflow="hidden"
      pos="relative"
    >
      <LazyLoadComponent>
        <Link to={`/${data.slug}-${data.id}`}>
          <Box maxW="350px" maxH="233px" pos="relative">
            <Image
              pos="relative"
              objectFit="cover"
              src={
                data.better_featured_image
                  ? data.better_featured_image.source_url
                  : Bckg
              }
            />
          </Box>

          <Box
            p="6"
            pos="relative"
            backgroundColor="white"
            border="1px solid white"
          >
            <Box d="flex" alignItems="baseline" pos="relative">
              <Badge rounded="full" px="2" variantColor="teal">
                Tag
              </Badge>
              {/*  <Box
              color="gray.500"
              fontWeight="semibold"
              letterSpacing="wide"
              fontSize="xs"
              textTransform="uppercase"
              ml="2"
            >
              beds &bull; baths
            </Box> */}
            </Box>

            <Box
              mt="1"
              fontWeight="semibold"
              as="h4"
              lineHeight="tight"
              isTruncated
            >
              {data.title.rendered}
            </Box>

            <Box d="flex" mt="2" alignItems="center">
              <Box as="span" ml="2" color="gray.600" fontSize="sm">
                Opis
              </Box>
            </Box>
          </Box>
        </Link>
      </LazyLoadComponent>
    </MBox>
  );
};

export default GastroCard;
