import React from "react";
import { Box, Badge, Image } from "@chakra-ui/core";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import Bckg from "../../assets/Rectangle.png";
//import { formatMonth } from "../../helpers/functions";

const MBox = motion.custom(Box);

const transition = { duration: 0.5, ease: [0.43, 0.13, 0.23, 0.96] };

const thumbnailVariants = {
  initial: { scale: 0.9, opacity: 0 },
  enter: { scale: 1, opacity: 1, transition },
  exit: {
    scale: 0.5,
    opacity: 0,
    transition: { duration: 1, ...transition },
  },
  hover: { scale: 1.05 },
};

const TravelCard = ({ data, maxH }) => {
  return (
    <Link to={`/${data.slug}-${data.id}`}>
      <MBox
        m={{ base: "5px", lg: "10px" }}
        variants={thumbnailVariants}
        cursor="pointer"
        maxW="lg"
        borderWidth="0px"
        rounded="lg"
        whileHover="hover"
        overflow="hidden"
        pos="relative"
        d="flex"
        boxShadow=" rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px"
      >
        <Box d="flex" flexDir="row" flexWrap="no-wrap" w="100%">
          <Box
            pos="relative"
            d="flex"
            flexDir="column"
            flexBasis="100%"
            flex="1"
            height="200px"
            maxW={{ base: "120px", md: "unset" }}
          >
            <Image
              pos="relative"
              objectFit="cover"
              h="100%"
              src={
                data.better_featured_image
                  ? data.better_featured_image.source_url
                  : Bckg
              }
            />
          </Box>

          <Box
            p="6"
            pos="relative"
            backgroundColor="white"
            border="1px solid white"
            d="flex"
            flexDir="column"
            flexBasis="100%"
            flex="1"
            height="200px"
            maxW={{ base: "195px", md: "unset" }}
          >
            <Box d="flex" alignItems="baseline" pos="relative">
              <Badge rounded="full" px="2" variantColor="teal">
                New
              </Badge>
             {/*  <Box
                color="gray.500"
                fontWeight="semibold"
                letterSpacing="wide"
                fontSize="xs"
                textTransform="uppercase"
                ml="2"
              >
                beds &bull; baths
              </Box> */}
            </Box>

            <Box
              mt="1"
              fontWeight="semibold"
              as="h4"
              lineHeight="tight"
              isTruncated
            >
              {data.title.rendered}
            </Box>

            <Box d="flex" mt="2" alignItems="center">
              <Box as="span" ml="2" color="gray.600" fontSize="sm">
                Opis
              </Box>
            </Box>
          </Box>
        </Box>
      </MBox>
    </Link>
  );
};

export default TravelCard;
