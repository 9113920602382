import React from "react";
import { Flex, Divider, Image } from "@chakra-ui/core";
import { Link } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import Search from "./Search";
import MobileSearch from "./MobileSearch";
import Logo from "../../assets/logo.svg";

const MotionFlex = motion.custom(Flex);

const Header = ({ size }) => {
  return (
    <>
      <AnimatePresence>
        <MotionFlex
          as="nav"
          align="center"
          justify="space-between"
          wrap="wrap"
          initial={{
            opacity: 0,
            y: -50,
          }}
          animate={{
            opacity: 1,
            y: 0,
            transition: { ease: [0.43, 0.13, 0.23, 0.96], duration: 1 },
          }}
          padding="0.5rem"
          color="white"
          maxW="1400px"
          mx="auto"
        >
          <Flex align="center" mr={5}>
            <Link to="/">
              <Image src={Logo} />
            </Link>
          </Flex>
          {size.width > 700 ? <Search /> : <MobileSearch />}
        </MotionFlex>
      </AnimatePresence>

      <Divider />
    </>
  );
};

export default Header;
