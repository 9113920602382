import React, { useRef, useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { Heading, Box, Flex, Grid, Image } from "@chakra-ui/core";
import MainGallery from "../MainGallery/MainGallery";
import GalleryPage from "../GalleryPage/GalleryPage";
import Gastro from "../Gastro/Gastro";
import Travel from "../Travel/Travel";
import Events from "../Events/Events";
import Wave from "../../assets/wave.svg";
import styled from "@emotion/styled";

const MImage = motion.custom(Image);
const MFlex = motion.custom(Flex);

const transition = {
  duration: 1,
  ease: [0.43, 0.13, 0.23, 0.96],
};
const imageVariants = {
  exit: { x: "-50%", opacity: 0, transition },
  enter: {
    x: "0%",
    opacity: 1,
    transition,
  },
};
const titleVariant = {
  exit: { y: "10%", opacity: 0, transition },
  enter: {
    y: "0%",
    opacity: 1,
    transition,
  },
};
const Main = () => {
  const topRef = useRef(null);
  const gastroRef = useRef(null);
  const travelRef = useRef(null);
  const controls = useAnimation();
  const controls2 = useAnimation();

  const [ref, inView] = useInView();
  const [ref2, inView2] = useInView();

  useEffect(() => {
    if (inView) {
      controls.start("enter");
    }
    if (inView2) {
      controls2.start("enter");
    }
  }, [controls, controls2, inView, inView2]);
  return (
    <>
      <MFlex
        initial="exit"
        animate="enter"
        exit="exit"
        maxW="1400px"
        flexWrap="wrap"
        mx="auto"
        px="15px"
      >
        <MainGallery
          galleryName={"allMainPosts"}
          url={"https://www.viahr-wp.parpar.hr/wp-json/wp/v2/posts?_embed=1"}
        />
        <MImage
          variants={imageVariants}
          src={Wave}
          mx="auto"
          width="100%"
          h="25px"
          mt="auto"
        ></MImage>

        <Box>
          <MTitle
            variants={titleVariant}
            mt={20}
            mb={10}
            ref={topRef}
            w="200px"
          >
            Najnovije
          </MTitle>
          <Grid
            templateColumns={{ base: "1fr", md: "3fr 1fr" }}
            spacing={{ base: 1, lg: 10 }}
          >
            <GalleryPage
              scrollTo={topRef}
              url="https://www.viahr-wp.parpar.hr/wp-json/wp/v2/posts?_embed"
              queryName="mainPosts"
            />
            <Events />
          </Grid>
        </Box>
      </MFlex>
      <MFlex
        initial="exit"
        animate={controls2}
        exit="exit"
        mx="auto"
        mt="10px"
        pb="25px"
        px="15px"
        flexDir="column"
        backgroundColor="#FEEBC8"
        ref={ref2}
      >
        <Box maxW="1400px" mx="auto" w="100%">
          <Box>
            <MTitle
              variants={titleVariant}
              mt={20}
              mb={10}
              w="155px"
              clr="#E53E3E"
              ref={gastroRef}
              textClr="#3e3e3e"
            >
              Gastro
            </MTitle>
          </Box>
        </Box>
        <Box mx="auto">
          <Gastro
            scrollTo={gastroRef}
            url={"https://www.viahr-wp.parpar.hr/wp-json/wp/v2/posts?_embed=1"}
          />
        </Box>
      </MFlex>
      <MFlex
        initial="exit"
        animate={controls}
        exit="exit"
        mx="auto"
        pb="10px"
        px="15px"
        flexDir="column"
        ref={ref}
        /* backgroundColor="#FEEBC8" */
      >
        <MImage
          variants={imageVariants}
          src={Wave}
          mx="auto"
          width="100%"
          h="25px"
          mt="25px"
        ></MImage>
        <Box maxW="1400px" mx="auto" w="100%">
          <Box>
            <MTitle
              variants={titleVariant}
              mt={20}
              mb={10}
              w="205px"
              clr="#4299E1"
              ref={travelRef}
              textClr="#3e3e3e"
            >
              Putovanja
            </MTitle>
          </Box>
        </Box>
        <Box mx="auto">
          <Travel
            queryName="travelQueries"
            scrollTo={travelRef}
            url={"https://www.viahr-wp.parpar.hr/wp-json/wp/v2/posts?_embed=1"}
          />
        </Box>
      </MFlex>
    </>
  );
};

export default Main;

const Title = styled(Heading)`
  margin-top: 20px;
  margin-bottom: 50px;
  position: relative;
  padding: 0 0.5em;
  color: ${(props) => (props.textClr ? props.textClr : "black")};
  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    border-radius: 25px;
    left: 0;
    width: 100%;
    height: 35%;
    background-color: ${(props) => (props.clr ? props.clr : "lightblue")};
    z-index: -10;
    -webkit-transition: all 0.1s linear;
    -moz-transition: all 0.1s linear;
    transition: all 0.1s linear;
  }
`;
const MTitle = motion.custom(Title);
