import axios from "axios";

const instance = axios.create();

export function handlePostRequest(url, options, token) {
  //console.log(url, options, token);

  return instance.post(url, ...options, {
    headers: {
      Authorization: `JWT ${token}`,
    },
  });
}
export function handleGetRequest(url) {
  return instance.get(url);
}
