import React from "react";
import { Flex, Link, Box } from "@chakra-ui/core";
import { AnimateSharedLayout, motion } from "framer-motion";
import { Link as ReactLink, useRouteMatch } from "react-router-dom";
import "./Nav.css";

const screens = [
  {
    title: "Početna",
    color: "#ff0055",
    to: "/",
  },
  {
    title: "Na putu",
    color: "#0099ff",
    to: "/na-putu",
  },
  {
    title: "Fun",
    color: "#22cc88",
    to: "/fun",
  },
  {
    title: "Za stolom",
    color: "#ffaa00",
    to: "/za-stolom",
  },
  {
    title: "Zdravlje",
    color: "#4FD1C5",
    to: "/zdravlje",
  },
  {
    title: "Relax",
    color: "#805AD5",
    to: "/relax",
  },
];
const MLink = motion.custom(ReactLink);

const MenuItem = ({ title, color, to, i }) => {
  let match = useRouteMatch({
    path: to,
    exact: true,
  });

  return (
    <Link
      animate
      as={MLink}
      to={to}
      _hover={{ textDecor: "none" }}
      _focus={{ outline: "none" }}
      key={i}
      ml={i === 0 ? 0 : { base: "1em", md: 30 }}
      mr={i === screens.length - 1 ? "1em" : 0}
      className={`title ${match && "selected"}`}
      style={{ color: match ? color : "#333" }}
      fontSize={{ base: 24, md: 26 }}
    >
      {match && (
        <motion.div
          layoutId="underline"
          className="underline"
          style={{ backgroundColor: color }}
        />
      )}
      {title}
    </Link>
  );
};

const Nav = () => {
  return (
    <>
      <Flex
        justifyContent="center"
        flexWrap="wrap"
        alignItems="center"
        className="scroll"
      >
        <Box as="nav" px="4" d={{ base: "initial", md: "flex" }}>
          <AnimateSharedLayout>
            {screens.map(({ title, color, to }, i) => (
              <MenuItem key={title} title={title} color={color} i={i} to={to} />
            ))}
          </AnimateSharedLayout>
        </Box>
      </Flex>
    </>
  );
};

export default Nav;
