import React from "react";
import { Box, Heading, Text, IconButton, Stack, Tag } from "@chakra-ui/core";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import Bckg from "../../assets/Rectangle.png";
import { LazyLoadComponent } from "react-lazy-load-image-component";
import "./Card.css";

const MBox = motion.custom(Box);

const transition = { duration: 0.5, ease: [0.43, 0.13, 0.23, 0.96] };

const thumbnailVariants = {
  initial: { scale: 0.9, opacity: 0 },
  enter: { scale: 1, opacity: 1, transition },
  exit: {
    scale: 0.5,
    opacity: 0,
    transition: { duration: 1, ...transition },
  },
};

const frameVariants = {
  hover: { scale: 0.95 },
};

const imageVariants = {
  hover: { scale: 1.1 },
};

const Card = ({
  data,
  noDesc,
  firstCard,
  id,
  i,
  height,
  btnMtm,
  maxW,
  maxH,
  btnSize,
}) => {
  return data ? (
    <MBox
      className="thumbnail"
      m={{ base: "5px", lg: "10px" }}
      variants={thumbnailVariants}
      maxH={maxH ? maxH : "450px"}
      key="item card"
      mb={8}
    >
      <MBox
        className="frame"
        whileHover="hover"
        variants={frameVariants}
        transition={transition}
        maxW={maxW}
        maxH={maxH ? maxH : "450px"}
        style={{ position: "relative", borderRadius: 25 }}
        height={height}
      >
        <Link to={`/${data.slug}-${data.id}`}>
          <LazyLoadComponent>
            <motion.img
              className="imageBox"
              src={
                data.better_featured_image
                  ? data.better_featured_image.source_url
                  : Bckg
              }
              variants={imageVariants}
              transition={transition}
              width="100%"
            ></motion.img>
          </LazyLoadComponent>
          <Heading
            top={5}
            left={5}
            pos="absolute"
            color="white"
            fontSize={{
              base: firstCard ? 26 : 18,
              sm: 16,
              lg: firstCard ? "3xl" : "lg",
            }}
          >
            {data.title.rendered}
          </Heading>
          <Stack spacing={4} isInline pos="absolute" top={65} left="20px">
            {data._embedded["wp:term"][0].map((item) => {
              return (
                <Tag size="sm" key={item} variantColor="cyan">
                  {item.name}
                </Tag>
              );
            })}
          </Stack>
          {noDesc ? null : (
            <Text
              top={100}
              left="20px"
              pos="absolute"
              color="white"
              dangerouslySetInnerHTML={{ __html: data.excerpt.rendered }}
              display={{ base: "none", md: "initial" }}
            ></Text>
          )}
          <IconButton
            bottom={5}
            size={btnSize}
            right={15}
            borderRadius={25}
            pos="absolute"
            d="flex"
            icon="chevron-right"
            ml="auto"
          ></IconButton>
        </Link>
      </MBox>
    </MBox>
  ) : null;
};

export default Card;
