import React from "react";
import { motion } from "framer-motion";
import { Box, SimpleGrid, Skeleton } from "@chakra-ui/core";
import Card from "../Card/Card";
import { handleGetRequest } from "../../helpers/handle-response";
import { useQuery } from "react-query";
import { useTraceUpdate } from "../../hooks/index";

const MainGallery = (props) => {
  useTraceUpdate(props);

  const { isLoading, data } = useQuery(props.galleryName, () =>
    handleGetRequest(props.url).then((res) => {
      return res.data.slice(0, 5);
    })
  );
  const firstPost = data && data[0];
  const otherPosts = data && data.slice(1, 5);
  return isLoading ? (
    <Box pos="relative" mx="auto">
      <SimpleGrid
        columns={{ base: 1, lg: 2 }}
        spacing={{ base: 1, lg: 10 }}
        w="100%"
        mx={{ base: "auto", sm: "auto" }}
        mt={20}
        px={{ base: 0, md: 10 }}
        mb={16}
      >
        <SimpleGrid columns={1} mx="auto">
          <Skeleton
            mx="auto"
            w={{ base: "290px", xl: "604px" }}
            h={{ base: "205px", md: "450px" }}
            borderRadius="25px"
          ></Skeleton>
        </SimpleGrid>
        <SimpleGrid
          columns={{ base: 1, md: 2, lg: 2 }}
          spacing={{ base: 1, lg: 5 }}
          mx="auto"
        >
          {[1, 2, 3, 4].map((item) => {
            return (
              <Skeleton
                mx="auto"
                key={item}
                w="290px"
                h="205px"
                borderRadius="25px"
              />
            );
          })}
        </SimpleGrid>
      </SimpleGrid>
    </Box>
  ) : (
    <div className="gallery">
      <motion.div
        className="thumbnails"
        initial="initial"
        animate="enter"
        exit="exit"
        variants={{ exit: { transition: { staggerChildren: 0.1 } } }}
      >
        <SimpleGrid
          columns={{ base: 1, md: 2 }}
          spacing={{ base: 1, lg: 10 }}
          w="100%"
          mx={{ base: 0, sm: "auto" }}
          mt={20}
          px={{ base: 0, md: 10 }}
          mb={16}
        >
          <SimpleGrid columns={1}>
            <Card
              height="100%"
              firstCard={true}
              btnMt="9rem"
              data={firstPost}
            />
          </SimpleGrid>
          <SimpleGrid
            columns={{ base: 1, md: 2, lg: 2 }}
            spacing={{ base: 1, lg: 5 }}
          >
            {otherPosts.map((item) => {
              return (
                <Card
                  key={item.id}
                  height="205px"
                  noDesc={true}
                  btnMt={1}
                  btnSize="sm"
                  data={item}
                />
              );
            })}
          </SimpleGrid>
        </SimpleGrid>
      </motion.div>
    </div>
  );
};
export default MainGallery;
