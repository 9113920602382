import React, { useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import {
  Box,
  IconButton,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
} from "@chakra-ui/core";
import { AnimatePresence, motion } from "framer-motion";
import { useOnClickOutside } from "../../hooks/index";
import { useForm } from "react-hook-form";

const inputVariants = {
  initial: {
    opacity: 0,
    x: 50,
    scale: 0.3,
  },
  enter: {
    opacity: 1,
    x: 0,
    scale: 1,
  },
  exit: {
    opacity: 0,
    scale: 0.5,
    transition: { duration: 0.3 },
  },
};
const MotionButton = motion.custom(IconButton);
const MInput = motion.custom(Input);

const SearchModal = ({ open, setOpen, onClose }) => {
  let history = useHistory();
  const root = useRef(null);
  const { register, handleSubmit, errors } = useForm();

  useOnClickOutside(root, () => {
    setOpen({ icon: false, input: false });
    setTimeout(() => {
      setOpen({ icon: true, input: false });
    }, 300);
  });
  const submit = (data) => {
    console.log(data);
    /*  if (history.location.pathname.includes("/search")) {
      window.location.reload(`/search/${data.search}`);
    } else { */
    history.push(`/search/${data.search}`);
    setOpen({ icon: true, input: false });
    /*  } */
  };
  return (
    <AnimatePresence exitBeforeEnter initial={false}>
      <Modal isOpen={open} onClose={onClose}>
        <ModalOverlay backgroundColor="rgba(0,0,0,0.8)" />
        <ModalContent backgroundColor="transparent">
          <ModalBody>
            <motion.form
              autoComplete="off"
              ref={root}
              onSubmit={handleSubmit(submit)}
            >
              <MInput
                name="search"
                ref={register({ required: "Obavezno polje" })}
                initial="exit"
                animate="enter"
                exit="exit"
                variants={inputVariants}
                autoFocus
                placeholder="Pretraga..."
                variant="flushed"
                zIndex={9999999}
                maxWidth="250px"
                mx="auto"
                p="10px"
                color="white"
                bg="transparent"
                icon="search"
              />
              {errors.search && (
                <Box as="span" color="red.500" textAlign="center">
                  Obavezno polje
                </Box>
              )}
            </motion.form>
          </ModalBody>
        </ModalContent>
      </Modal>
    </AnimatePresence>
  );
};
const MobileSearch = () => {
  const [searchOpen, setOpen] = useState({ icon: true, input: false });

  /* useOnClickOutside(root, () => {
    setOpen({ icon: false, input: false });
    setTimeout(() => {
      setOpen({ icon: true, input: false });
    }, 300);
  }); */

  return (
    <Box mt={{ base: 2, md: 0 }}>
      <AnimatePresence exitBeforeEnter initial={false}>
        {searchOpen.icon && (
          <MotionButton
            key={"button"}
            onClick={() => {
              setOpen({ input: false, icon: false });
              setTimeout(() => {
                setOpen({ icon: false, input: true });
              }, 300);
            }}
            whileHover={{ y: -3, transition: { duration: 0.05 } }}
            initial={{
              x: -10,
              opacity: 0,
              transition: { duration: 0.05 },
            }}
            animate={{ opacity: 1, x: 0, scale: 1 }}
            exit={{
              opacity: 0,
              scale: 0.5,
              x: 20,
              transition: { duration: 0.3 },
            }}
            bg="transparent"
            color="black"
            icon="search"
          ></MotionButton>
        )}
      </AnimatePresence>
      <AnimatePresence exitBeforeEnter initial={false}>
        <SearchModal key="modal" setOpen={setOpen} open={searchOpen.input} />
      </AnimatePresence>
    </Box>
  );
};
export default MobileSearch;
