import React from "react";
import parse, { domToReact } from "html-react-parser";
import { Text, Image } from "@chakra-ui/core";

export const PostContent = (props) => {
  const options = {
    replace: ({ name, attribs, children }) => {
      if (!attribs) return;

      if (name === "p") {
        return (
          <Text fontSize={{ base: 14, sm: 18, md: 22 }}>
            {domToReact(children, options)}
          </Text>
        );
      }

      if (name === "img") {
        return <Image src={attribs.src} mx="auto" height="100%"></Image>;
      }
    },
  };
  return parse(props.content, options);
};
