import React from "react";
import { motion } from "framer-motion";
import { Grid, IconButton, Box, Skeleton } from "@chakra-ui/core";
import Card from "../Card/Card";
import { handleGetRequest } from "../../helpers/handle-response";
import { usePaginatedQuery } from "react-query";


const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop);

//http://localhost/viahr/wordpress/index.php/wp-json/wp/v2/posts?_embed=1
const GalleryPage = (props) => {
  const [page, setPage] = React.useState(1);
  const [totalPages, setTotalPages] = React.useState(1);
  const topRef = props.scrollTo;

  const { isLoading, data } = usePaginatedQuery(
    [props.queryName, page],
    (key, page = 1) =>
      handleGetRequest(props.url + `&per_page=8&page=${page}`).then((res) => {
        setTotalPages(Number(res.headers["x-wp-totalpages"]));
        return res.data;
      })
  );

  const executeScroll = () => scrollToRef(topRef);

  return (
    <motion.div
      initial="initial"
      animate="enter"
      exit="exit"
      variants={{ exit: { transition: { staggerChildren: 0.1 } } }}
    >
      <Grid
        templateColumns={{ base: "1fr", md: "1fr 1fr" }}
        maxWidth="900px"
        width="100%"
        mx="auto"
      >
        {data && !isLoading ? (
          data.map((item) => {
            return (
              <Card
                key={item.id}
                maxW="350px"
                maxH="233.5px"
                noDesc={true}
                btnMt={1}
                btnSize="sm"
                data={item}
              />
            );
          })
        ) : (
          <Grid
            templateColumns={{ base: "1fr", md: "1fr 1fr" }}
            maxWidth="1100px"
            width="100%"
            mx="auto"
          >
            {[1, 2, 3, 4, 5, 6, 7, 8].map((item) => {
              return (
                <Skeleton
                  key={item}
                  width="360px"
                  height="250px"
                  m="15px"
                  borderRadius="25px"
                ></Skeleton>
              );
            })}
          </Grid>
        )}
      </Grid>
      <Box maxW="900px" mx="auto">
        {page < totalPages ? (
          <IconButton
            backgroundColor="gray.400"
            w="55px"
            h={30}
            color="white"
            ml="4em"
            icon="arrow-forward"
            borderRadius={25}
            _hover={{
              backgroundColor: "gray.500",
              transform: "scale(1.1)",
              transition: 0.3,
            }}
            onClick={() => {
              setPage(page + 1);
              executeScroll();
            }}
          >
            Sljedeća
          </IconButton>
        ) : null}
        {page > 1 && page <= totalPages ? (
          <IconButton
            backgroundColor="gray.400"
            w="55px"
            ml="4em"
            icon="arrow-back"
            color="white"
            borderRadius={25}
            h={30}
            _hover={{
              backgroundColor: "gray.500",
              transform: "scale(1.1)",
              transition: 0.3,
            }}
            onClick={() => {
              setPage(page - 1);

              executeScroll();
            }}
          >
            Natrag
          </IconButton>
        ) : null}
      </Box>
    </motion.div>
  );
};
export default GalleryPage;
