const months = {
  0: "siječnja",
  1: "veljače",
  2: "ožujka",
  3: "travnja",
  4: "svibnja",
  5: "lipnja",
  6: "srpnja",
  7: "kolovoza",
  8: "rujna",
  9: "listopada",
  10: "studenoga",
  11: "prosinca",
};

export const formatDate = (value) => {
  const day = new Date(value).getDate();
  const month = months[new Date(value).getMonth()];
  const year = new Date(value).getFullYear();
  return `${day}. ${month} ${year}.`;
};
