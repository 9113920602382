import React, { useState } from "react";
import { motion } from "framer-motion";
import { Grid, IconButton, Skeleton } from "@chakra-ui/core";
import { usePaginatedQuery } from "react-query";
import { handleGetRequest } from "../../helpers/handle-response";
import GastroCard from "./GastroCard";
import { useInView } from "react-intersection-observer";

const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop);

const Gastro = (props) => {
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const executeScroll = () => scrollToRef(topRef);
  const topRef = props.scrollTo;
  const [ref, inView] = useInView();

  const { isLoading, data } = usePaginatedQuery(
    [props.queryName, page],
    (key, page = 1) =>
      handleGetRequest(props.url + `&per_page=6&page=${page}`).then((res) => {
        setTotalPages(Number(res.headers["x-wp-totalpages"]));
        return res.data;
      }),
    { enabled: inView }
  );

  return (
    <motion.div
      initial="initial"
      animate={inView ? "enter" : null}
      ref={ref}
      exit="exit"
      variants={{ exit: { transition: { staggerChildren: 0.1 } } }}
    >
      <Grid
        templateColumns={{ base: "1fr", md: "1fr 1fr 1fr" }}
        maxWidth="1100px"
        width="100%"
        mx="auto"
      >
        {data && !isLoading ? (
          data.map((item) => {
            return (
              <GastroCard
                key={item.id}
                maxW="350px"
                maxH="233.5px"
                noDesc={true}
                btnMt={1}
                btnSize="sm"
                data={item}
              />
            );
          })
        ) : (
          <Grid
            templateColumns={{ base: "1fr", md: "1fr 1fr 1fr" }}
            maxWidth="1100px"
            width="100%"
            mx="auto"
          >
            {[1, 2, 3, 4, 5, 6].map((item) => {
              return (
                <Skeleton
                  key={item}
                  height="345px"
                  width="345px"
                  m="15px"
                  borderRadius="25px"
                ></Skeleton>
              );
            })}
          </Grid>
        )}
      </Grid>
      <div>
        {page < totalPages ? (
          <IconButton
            backgroundColor="gray.400"
            w="55px"
            h={30}
            color="white"
            ml="1em"
            icon="arrow-forward"
            borderRadius={25}
            _hover={{
              backgroundColor: "gray.500",
              transform: "scale(1.1)",
              transition: 0.3,
            }}
            onClick={() => {
              executeScroll();
              setPage(page + 1);
            }}
          >
            Sljedeća
          </IconButton>
        ) : null}
        {page > 1 && page <= totalPages ? (
          <IconButton
            backgroundColor="gray.400"
            w="55px"
            ml="1em"
            icon="arrow-back"
            color="white"
            borderRadius={25}
            h={30}
            _hover={{
              backgroundColor: "gray.500",
              transform: "scale(1.1)",
              transition: 0.3,
            }}
            onClick={() => {
              executeScroll();
              setPage(page - 1);
            }}
          >
            Natrag
          </IconButton>
        ) : null}
      </div>
    </motion.div>
  );
};

export default Gastro;
