import React from "react";
import { Link as RLink } from "react-router-dom";
import { Box, Grid, Flex, Image, Text, Link, Heading } from "@chakra-ui/core";
import { motion, AnimatePresence } from "framer-motion";
import Logo from "../../assets/logo.svg";

const MBox = motion.custom(Box);
const MLink = motion.custom(Link);

const screens = [
  {
    title: "Početna",
    color: "#ff0055",
    to: "/",
  },
  {
    title: "Na putu",
    color: "#0099ff",
    to: "/na-putu",
  },
  {
    title: "Fun",
    color: "#22cc88",
    to: "/fun",
  },
  {
    title: "Za stolom",
    color: "#ffaa00",
    to: "/za-stolom",
  },
  {
    title: "Zdravlje",
    color: "#4FD1C5",
    to: "/zdravlje",
  },
  {
    title: "Relax",
    color: "#805AD5",
    to: "/relax",
  },
];

const transition = {
  duration: 1,
  ease: [0.43, 0.13, 0.23, 0.96],
  delay: 1,
};
const boxVariant = {
  exit: { y: "10%", opacity: 0, transition },
  enter: {
    y: "0%",
    opacity: 1,
    transition,
  },
};
const Footer = () => {
  return (
    <AnimatePresence exitBeforeEnter initial={true}>
      <motion.div initial="exit" animate="enter" exit="exit">
        <MBox
          variants={boxVariant}
          backgroundColor="gray.600"
          pos="relative"
          mt="5em"
        >
          <Box maxW="1100px" mx="auto" pt="16px" px={{ base: 8, md: 0 }}>
            <Grid
              templateColumns={{ base: "1fr", md: "1fr 1fr 1fr 1fr" }}
              width="100%"
            >
              <Flex justifyContent="center" flexDir="column">
                <Image src={Logo} />
                <Text color="white" mt="2em" textAlign="center">
                  &copy; Via.hr 2020
                </Text>
              </Flex>
              <Flex justifyContent="center" flexDir="column">
                <Heading color="white">Izbornik</Heading>
                <Box my={4} as="ul">
                  {screens.map((item) => {
                    return (
                      <li key={item.title}>
                        <MLink
                          _hover={{ textDecor: "none", color: item.color }}
                          _focus={{ outline: "none" }}
                          color="white"
                          fontWeight={500}
                          as={RLink}
                          to={item.to}
                        >
                          {item.title}
                        </MLink>
                      </li>
                    );
                  })}
                </Box>
              </Flex>
              <Flex justifyContent="flex-start" flexDir="column">
                <Heading color="white">Kontakt</Heading>
                <Box my={4} as="ul" color="white">
                  <li>adresa</li>
                  <li>broj</li>
                </Box>
              </Flex>
              <Flex justifyContent="flex-start" pb={{ base: 6, md: 0 }}>
                <Heading as="h5" color="white" fontSize="1.5em" mt="15px">
                  <Link
                    _hover={{ textDecor: "none" }}
                    _focus={{ outline: "none" }}
                    as={MLink}
                  >
                    O nama
                  </Link>
                </Heading>
              </Flex>
            </Grid>
          </Box>
        </MBox>
      </motion.div>
    </AnimatePresence>
  );
};

export default Footer;
