import React from "react";
import { useParams } from "react-router-dom";
import { motion } from "framer-motion";
import { useQuery } from "react-query";
import { Image, Box, Heading, Text } from "@chakra-ui/core";
import { handleGetRequest } from "../../helpers/handle-response";
import { PostContent } from "../../helpers/components";
import { formatDate } from "../../helpers/functions";

import "./Post.scss";

const MHeading = motion.custom(Heading);

const transition = {
  duration: 0.6,
  ease: [0.43, 0.13, 0.23, 0.96],
};

const headingVariants = {
  initial: { y: "-10%", opacity: 0, transition },
  exit: { y: "10%", opacity: 0, transition },
  enter: {
    y: "0%",
    opacity: 1,
    transition,
  },
};

const Post = () => {
  let { id } = useParams();
  const index = id.lastIndexOf("-");
  const postID = Number(id.substr(index + 1, id.length));
  const { data } = useQuery(`singlePost${id}`, () =>
    handleGetRequest(
      `https://www.viahr-wp.parpar.hr/wp-json/wp/v2/posts/${postID}?_embed=1`
    ).then((res) => {
      return res.data;
    })
  );
  const postData = data && data.content.rendered;
  return data ? (
    <motion.div initial="initial" animate="enter" exit="exit">
      <Box maxW={900} mx="auto" px={{ base: 6, md: 2 }}>
        <motion.div variants={headingVariants}>
          <MHeading mx="auto" my={6} textAlign="center">
            {data.title.rendered}
          </MHeading>
          <Box textAlign="center">
            <Text>{formatDate(data.date)}</Text>
          </Box>
          <Image
            src={
              data.better_featured_image
                ? data.better_featured_image.source_url
                : null
            }
            w={650}
            mx="auto"
            borderRadius="20px"
            my={6}
          ></Image>
          <div className="post">
            <PostContent content={postData}></PostContent>
          </div>
        </motion.div>
      </Box>
    </motion.div>
  ) : (
    <>
      <Box height="100vh"></Box>
    </>
  );
};
export default Post;
