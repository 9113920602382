import React from "react";
import { Box, Heading, Text } from "@chakra-ui/core";
//import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import Bckg from "../../assets/Rectangle.png";

import style from "./EventCard.module.scss";

const MBox = motion.custom(Box);

const transition = { duration: 0.5, ease: [0.43, 0.13, 0.23, 0.96] };

const thumbnailVariants = {
  initial: { scale: 0.9, opacity: 0 },
  enter: { scale: 1, opacity: 1, transition },
  exit: {
    scale: 0.5,
    opacity: 0,
    transition: { duration: 1, ...transition },
  },
};

const frameVariants = {
  hover: { scale: 0.95 },
};

const imageVariants = {
  hover: { scale: 1.1 },
};

const EventCard = ({ data, i }) => {
  return (
    <MBox
      className={style.thumbnail}
      m={{ base: "5px", lg: "10px" }}
      variants={thumbnailVariants}
      height="150px"
    >
      <MBox
        className={style.frame}
        whileHover="hover"
        variants={frameVariants}
        transition={transition}
        style={{ position: "relative", borderRadius: 25 }}
        height="150px"
      >
        <motion.img
          src={Bckg}
          variants={imageVariants}
          transition={transition}
          width="100%"
        ></motion.img>
        <Heading
          top={5}
          left={5}
          pos="absolute"
          color="white"
          fontSize={{ base: 26, sm: 16, lg: "xl" }}
        >
          {data.naziv}
        </Heading>
        <Box top={60} left="26px" pos="absolute" color="white">
          {data.lokacija.map((item, index) => {
            return (
              <Box key={item + index} mr={2} as="span">
                {item}
              </Box>
            );
          })}
        </Box>
        <Text top={80} left="26px" pos="absolute" color="white">
          {data.opis.map((item, index) => {
            return (
              <Box key={item + index} mr={2} as="span">
                {item}
              </Box>
            );
          })}
        </Text>
        <Text top={100} left="26px" pos="absolute" color="white">
          {data.datum.map((item, index) => {
            return (
              <Box key={item + index} mr={2} as="span">
                {item}
              </Box>
            );
          })}
        </Text>
        {/*   <IconButton
            bottom={5}
            right={15}
            borderRadius={25}
            pos="absolute"
            d="flex"
            icon="chevron-right"
            ml="auto"
          ></IconButton> */}
      </MBox>
    </MBox>
  );
};

export default EventCard;
