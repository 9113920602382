import React, { useState, useRef, useEffect } from "react";
import { useParams } from "react-router-dom";
import { motion } from "framer-motion";
import { useQuery, queryCache } from "react-query";
import { handleGetRequest } from "../../helpers/handle-response";
import { Box, Heading, Grid, Skeleton, IconButton } from "@chakra-ui/core";
import Card from "../Card/Card";
import styled from "@emotion/styled";

const MBox = motion.custom(Box);

const SearchResult = () => {
  let { query } = useParams();
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop);
  const topRef = useRef(null);

  const { data, isLoading } = useQuery(
    `search`,
    () =>
      handleGetRequest(
        `https://www.viahr-wp.parpar.hr/wp-json/wp/v2/posts?search=${query}&per_page=12&page=${page}&_embed=1`
      ).then((res) => {
        setTotalPages(Number(res.headers["x-wp-totalpages"]));
        return { data: res.data, query };
      }),
    { enabled: query }
  );
  const executeScroll = () => scrollToRef(topRef);

  useEffect(() => {
    queryCache.invalidateQueries("search");
  }, [query]);
  return (
    <MBox
      exit="exit"
      initial="exit"
      animate="enter"
      maxW="1400px"
      mx="auto"
      mt="3rem"
    >
      <MTitle
        w={{ base: "250px", md: "350px" }}
        clr="#38A169"
        ref={topRef}
        mx={{ base: "1em", md: "unset" }}
        fontSize={{ base: "1.5em", md: "2.3em" }}
      >
        Rezultati pretrage
      </MTitle>
      <Heading as="h5" size="lg" px={{ base: "1em", md: "unset" }}>
        Tražili ste:{" "}
        <Box as="span" textDecor="underline">
          {query}
        </Box>
      </Heading>
      <Grid
        templateColumns={{ base: "1fr", md: "1fr 1fr 1fr" }}
        maxWidth="1100px"
        width="100%"
        mx="auto"
        mt="2em"
        px={{ base: "2em", md: "unset" }}
      >
        {data && !isLoading ? (
          data.data.map((item) => {
            return (
              <Card
                key={item.id}
                maxW="350px"
                maxH="233.5px"
                noDesc={true}
                btnMt={1}
                btnSize="sm"
                data={item}
              />
            );
          })
        ) : (
          <Grid
            templateColumns={{ base: "1fr", md: "1fr 1fr" }}
            maxWidth="1100px"
            width="100%"
            mx="auto"
          >
            {[1, 2, 3, 4, 5, 6, 7, 8].map((item) => {
              return (
                <Skeleton
                  key={item}
                  width="360px"
                  height="250px"
                  m="15px"
                  borderRadius="25px"
                ></Skeleton>
              );
            })}
          </Grid>
        )}
      </Grid>
      <Box maxW="900px" mx="auto">
        {page < totalPages ? (
          <IconButton
            backgroundColor="gray.400"
            w="55px"
            h={30}
            color="white"
            ml="4em"
            icon="arrow-forward"
            borderRadius={25}
            _hover={{
              backgroundColor: "gray.500",
              transform: "scale(1.1)",
              transition: 0.3,
            }}
            onClick={() => {
              setPage(page + 1);
              executeScroll();
            }}
          >
            Sljedeća
          </IconButton>
        ) : null}
        {page > 1 && page <= totalPages ? (
          <IconButton
            backgroundColor="gray.400"
            w="55px"
            ml="4em"
            icon="arrow-back"
            color="white"
            borderRadius={25}
            h={30}
            _hover={{
              backgroundColor: "gray.500",
              transform: "scale(1.1)",
              transition: 0.3,
            }}
            onClick={() => {
              setPage(page - 1);
              executeScroll();
            }}
          >
            Natrag
          </IconButton>
        ) : null}
      </Box>
    </MBox>
  );
};

export default SearchResult;

const Title = styled(Heading)`
  margin-top: 20px;
  margin-bottom: 50px;
  position: relative;
  padding: 0 0.5em;
  color: ${(props) => (props.textClr ? props.textClr : "#444444")};
  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    border-radius: 25px;
    left: 0;
    width: 100%;
    height: 35%;
    background-color: ${(props) => (props.clr ? props.clr : "lightblue")};
    z-index: -10;
    -webkit-transition: all 0.1s linear;
    -moz-transition: all 0.1s linear;
    transition: all 0.1s linear;
  }
`;
const MTitle = motion.custom(Title);
