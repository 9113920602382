import React, { useState, useRef } from "react";
import { motion } from "framer-motion";
import { handleGetRequest } from "../../helpers/handle-response";
import { usePaginatedQuery } from "react-query";
import { Flex, Heading, IconButton } from "@chakra-ui/core";
import styled from "@emotion/styled";
import EventCard from "./EventCard";
import { isArray, mergeWith } from "lodash";

function customizer(objValue, srcValue) {
  if (isArray(objValue)) {
    return objValue.concat(srcValue);
  }
}
const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop);

const transition = {
  duration: 1,
  ease: [0.43, 0.13, 0.23, 0.96],
};
const titleVariant = {
  exit: { y: "10%", opacity: 0, transition },
  enter: {
    y: "0%",
    opacity: 1,
    transition,
  },
};
const Events = () => {
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const topRef = useRef(null);
  const executeScroll = () => scrollToRef(topRef);

  const { data } = usePaginatedQuery([`events`, page], (key, page = 1) =>
    handleGetRequest(
      `https://www.viahr-wp.parpar.hr/wp-json/wp/v2/event?_embed=1&per_page=5&page=${page}`
    ).then((res) => {
      setTotalPages(Number(res.headers["x-wp-totalpages"]));
      const data = res.data
        .map((item) => {
          return {
            data: item._embedded["wp:term"],
            naziv: item.title.rendered,
          };
        })
        .map((item) => {
          const temp = [];
          item.data.flat().map((item) => {
            temp.push({ [item.taxonomy]: [item.name] });
            return null;
          });
          const temp2 = {};
          temp.forEach((item) => {
            mergeWith(temp2, item, customizer);
          });
          return { ...temp2, naziv: item.naziv };
        });
      return data;
    })
  );
  return (
    <Flex flexDir="column" maxWidth="400px" px={{ base: 0, md: 0 }}>
      <MTitle ref={topRef} variants={titleVariant} my={10} clr="#38B2AC">
        Događaji
      </MTitle>
      {data &&
        data.map((item, index) => {
          return <EventCard key={item + index} data={item} i={index} />;
        })}
      <div>
        {page < totalPages ? (
          <IconButton
            backgroundColor="gray.400"
            w="55px"
            h={30}
            color="white"
            ml="1em"
            icon="arrow-forward"
            borderRadius={25}
            _hover={{
              backgroundColor: "gray.500",
              transform: "scale(1.1)",
              transition: 0.3,
            }}
            onClick={() => {
              executeScroll();
              setPage(page + 1);
            }}
          >
            Sljedeća
          </IconButton>
        ) : null}
        {page > 1 && page <= totalPages ? (
          <IconButton
            backgroundColor="gray.400"
            w="55px"
            ml="1em"
            icon="arrow-back"
            color="white"
            borderRadius={25}
            h={30}
            _hover={{
              backgroundColor: "gray.500",
              transform: "scale(1.1)",
              transition: 0.3,
            }}
            onClick={() => {
              executeScroll();
              setPage(page - 1);
            }}
          >
            Natrag
          </IconButton>
        ) : null}
      </div>
    </Flex>
  );
};

export default Events;

const Title = styled(Heading)`
  margin-top: 20px;
  margin-bottom: 50px;
  position: relative;
  padding: 0 0.5em;
  color: ${(props) => (props.textClr ? props.textClr : "black")};
  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    border-radius: 25px;
    left: 0;
    width: 100%;
    height: 35%;
    background-color: ${(props) => (props.clr ? props.clr : "lightblue")};
    z-index: -10;
    -webkit-transition: all 0.1s linear;
    -moz-transition: all 0.1s linear;
    transition: all 0.1s linear;
  }
`;
const MTitle = motion.custom(Title);
